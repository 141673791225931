<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.redirect">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Redirect</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this redirect?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>
				<ti-form-layout>
					<template>
						<v-col cols="12">
							<v-text-field v-model="redirect.redirect_from" :rules="validations.redirect" label="From"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="redirect.redirect_to" :rules="validations.redirect" label="To"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {sync} from "vuex-pathify";

export default {
	name: "RedirectsEdit",
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		redirect: sync('redirects/redirect')
	},
	data: () => ({
		valid: false,
		validations: {
			name: validationRules('redirect', ['required', 'minLength:2']),
		}
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('redirects/getRedirect!', this.id)
			} else {
				this.redirect = {
					redirect_from: '',
					redirect_to: ''
				}
			}
		},
		getBrand() {
			this.$api.redirects.show(this.id)
				.then(response => {
					this.redirect = response.data
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.message, 'error')
					// this.$toast.handleResponseErrors(this, error)
				})
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.redirects.update(this.id, this.redirect)
						.then(response => {
							this.$toast.add('Redirect updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Redirects'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.redirects.create(this.redirect)
						.then(response => {
							this.$toast.add('Redirect created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Redirects'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}
			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.redirects.delete(this.id, this.track)
				.then(response => {
					this.$toast.add('Redirect deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'Redirects'})
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.statusText, 'error')
				})
		},
	}
}
</script>